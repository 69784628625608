import React from "react";
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import {Typography} from "@mui/material";

const OrdinalCriterionSelector = ({ name, description, levels, selectedLevel, onChange, disabled=false }) => {
    const findIndexByUUID = (uuid) => uuid ? levels.findIndex(level => level.id === uuid) : 0;

    const handleChange = (event, value) => {
        onChange(levels[value].id);
    };

    // Create marks based on levels
    const marks = levels.map((level, index) => ({
        value: index,
        label: level.label,
    }));

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    <Typography fontWeight="bold">{name}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    {description}
                </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
                <Box paddingLeft={2} paddingRight={2}>
                <Slider
                    disabled={disabled}
                    aria-label={name}
                    value={findIndexByUUID(selectedLevel)}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={marks}
                    min={0}
                    max={levels.length - 1}
                    onChange={handleChange}
                />
                </Box>
            </Grid>
        </Grid>
    );
};

export default OrdinalCriterionSelector;
