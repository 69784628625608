import { getCandidateSkills } from "./getCandidateSkills";

export function getInitialValues(idealCandidate, potentialCandidate) {
  return ({
    name: potentialCandidate?.candidate?.name || "",
    email: potentialCandidate?.candidate?.email || "",
    gender: potentialCandidate?.candidate?.gender || "prefer-not-to-say",
    phone: potentialCandidate?.candidate?.phone || "",
    race: potentialCandidate?.candidate?.race || "prefer-not-to-say",
    skills: getCandidateSkills(idealCandidate, potentialCandidate, 'skills'),
    behaviors: getCandidateSkills(idealCandidate, potentialCandidate, 'behaviors')
  });
}