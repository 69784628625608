import React, { useEffect, useState, useCallback } from "react";
import Link from '@mui/material/Link';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {archiveIdealCandidate, unarchiveIdealCandidate, getIdealCandidate, listMatches, createMatchPublic} from "../../ctapi/idealCandidates";
import IdealCandidateView from "./IdealCandidateView";
import { useOrganization } from "../../shared/OrganizationContext";
import {Box, Button, Dialog, DialogActions, Divider} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import DialogTitle from "@mui/material/DialogTitle";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DialogContent from "@mui/material/DialogContent";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from "react-router-dom";


function IdealCandidateBreadcrumbs({idealCandidate}) {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" component={RouterLink} to="/">
                Home
            </Link>
            <Link underline="hover" component={RouterLink} to="/ideal-candidates">
                            Ideal Candidates
            </Link>
            <Link underline="hover" component={RouterLink} to={`/ideal-candidates/${idealCandidate.id}`}>
                {idealCandidate.title}
            </Link>
        </Breadcrumbs>
    );
}

const IdealCandidateViewContainer = () => {
    const { idealCandidateUuid } = useParams();
    const { organization } = useOrganization();
    const [idealCandidate, setIdealCandidate] = useState(null);
    const [potentialCandidates, setPotentialCandidates] = useState([]);
    const navigate = useNavigate();
    const [openShareDialog, setOpenShareDialog] = useState(false);
    const potentialCandidateLink = `${window.location.protocol}//${window.location.host}/apply?ic=${idealCandidateUuid}`;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialStatuses = queryParams.get('statuses')
        ? queryParams.get('statuses').split(',')
        : ['pending', 'approved'];
    const minimumSkillScore = queryParams.get('skillScore')
        ? Number(queryParams.get('skillScore'))
        : 0;
    const minimumBehaviorScore = queryParams.get('behaviorScore')
        ? Number(queryParams.get('behaviorScore'))
        : 0;

    const filteredPotentialCandidates = potentialCandidates.filter((pc) => {
        const skillScoreObj = pc.scorecardResults.find(sc => sc.scorecardName === "skills");
        const behaviorScoreObj = pc.scorecardResults.find(sc => sc.scorecardName === "behaviors");
        const actualSkillScore = (skillScoreObj?.score ?? 0) * 100;
        const actualBehaviorScore = (behaviorScoreObj?.score ?? 0) * 100;

        return initialStatuses.includes(pc.status)
            && actualSkillScore >= minimumSkillScore
            && actualBehaviorScore >= minimumBehaviorScore;
    });

    const handleArchive = useCallback(async () => {
        await archiveIdealCandidate(organization, idealCandidateUuid);
        navigate('/ideal-candidates');
    }, [navigate, organization, idealCandidateUuid]);

    const handleUnarchive = useCallback(async () => {
        await unarchiveIdealCandidate(organization, idealCandidateUuid);
        window.location.reload();
    }, [organization, idealCandidateUuid]);

    const handleCreatePotentialCandidate = async (results) => {
        await createMatchPublic(idealCandidate, results);
        window.location.href = `/ideal-candidates/${idealCandidateUuid}`;
    };

    useEffect(() => {
        if (!idealCandidateUuid) {
            return;
        }

        getIdealCandidate(organization, idealCandidateUuid).then(ic => {
            const transformed = {
                id: idealCandidateUuid,
                title: ic.title,
                description: ic.description,
                archivedAt: ic.archivedAt,
                skills: ic.scorecards.find(scorecard => scorecard.name === 'skills').criteria.map(skill => {
                    return {
                        id: skill.id,
                        name: skill.label,
                        description: skill.description,
                        weight: skill.weight,
                        levels: skill.levels
                    }
                }),
                behaviors: ic.scorecards.find(scorecard => scorecard.name === 'behaviors').criteria.map(behavior => {
                    return {
                        id: behavior.id,
                        name: behavior.label,
                        description: behavior.description,
                        weight: behavior.weight,
                        levels: behavior.levels
                    }
                })
            };
            setIdealCandidate(transformed);
        });

        listMatches(organization, idealCandidateUuid).then((pcs) => {
            setPotentialCandidates(pcs["results"]);
        });
    }, [idealCandidateUuid, organization]);

    if (!idealCandidate) {
        return <div>Loading...</div>;
    }

    return (
        <Box>
            <Box mb={2} mt={1} display="flex" justifyContent="flex-start">
                <IdealCandidateBreadcrumbs idealCandidate={idealCandidate} />
            </Box>
            <Divider />
            {openShareDialog &&
                <Dialog open={openShareDialog} onClose={() => setOpenShareDialog(false)}>
                    <DialogTitle>Share Application Link</DialogTitle>
                    <DialogContent>
                        Use this link to share the application with potential candidates:
                        <br />
                        <br />
                        <a href={potentialCandidateLink} target="_blank" rel="noreferrer">{potentialCandidateLink}</a>
                        <br />
                        <br />
                        Note that anyone with this link will be able to apply to this position.
                    </DialogContent>
                    <DialogActions>
                        <Button startIcon={<ContentCopyIcon />} onClick={() => {navigator.clipboard.writeText(potentialCandidateLink); }}>
                            Copy Link
                        </Button>
                        <Button onClick={() => setOpenShareDialog(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <Box mt={2}>
                <IdealCandidateView idealCandidate={idealCandidate}
                                    potentialCandidates={filteredPotentialCandidates}
                                    onShare={() => setOpenShareDialog(true)}
                                    onArchive={handleArchive}
                                    onUnarchive={handleUnarchive}
                                    onCreatePotentialCandidate={handleCreatePotentialCandidate}
                                    initialStatuses={initialStatuses}
                                    initialBehaviorScore={minimumBehaviorScore}
                                    initialSkillScore={minimumSkillScore}
                />
            </Box>
        </Box>
    );
};

export default IdealCandidateViewContainer;
