import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Typography
} from "@mui/material";
import { usePatsContext } from "../../shared/PatContext";
import { PersonalAccessTokensModal } from "./PersonalAccessTokensModal";

const BoxStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "24px",
};

export default function PersonalAccessTokensForm() {
  const { handleCreatePat, pats } = usePatsContext();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [token, setToken] = useState(null);
  const isDisabled = pats?.length > 4;

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .nullable(false)
        .trim("Name cannot be just spaces")
        .max(255, "Name must be 255 characters or less"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const res = await handleCreatePat(values);
      if (res.result === 'success') {
        setShowCreateModal(true);
        setToken(res.token);
        resetForm();
      }
    },
  });

  function handleCloseModal() {
    setShowCreateModal(false);
    setToken(null);
  }

  return (
    <>
      <PersonalAccessTokensModal isOpen={showCreateModal} onClose={handleCloseModal}>
        <Typography variant="h6" component="h2">
          Your personal access token has been generated.
        </Typography>
        <Typography variant="h6" component="h2">
          {token}
        </Typography>
        <Typography variant="h6" component="h2" sx={{ textAlign: 'center' }}>
          Copy and paste this for future reference as you will not be able to access this value again.
        </Typography>
        <Button onClick={handleCloseModal} variant="contained">CLOSE</Button>
      </PersonalAccessTokensModal>

      <form onSubmit={formik.handleSubmit}>
        <Box sx={BoxStyle}>
          <TextField
            disabled={isDisabled}
            fullWidth
            required
            id="outlined-required"
            label="Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            inputProps={{ "data-testid": "name-input" }}
          />
          <Button
            disabled={isDisabled || !formik.isValid || !formik.dirty}
            sx={{ padding: "0px 32px" }}
            variant="contained"
            type="submit"
          >
            CREATE
          </Button>
        </Box>
      </form>
    </>
  );
}
