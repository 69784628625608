import React, { useEffect, useState } from 'react';
import {v4 as uuid4} from "uuid";
import { createIdealCandidate, listIdealCandidates } from '../../ctapi/idealCandidates';
import { useOrganization } from '../../shared/OrganizationContext';
import IdealCandidatesListing from '../IdealCandidatesListing/IdealCandidatesListing';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import posthog from "posthog-js";


function IdealCandidateListingBreadcrumbs() {
    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" component={RouterLink} to="/">
                Home
            </Link>
            <Link underline="hover" component={RouterLink} to="/ideal-candidates">
                Ideal Candidates
            </Link>
        </Breadcrumbs>
    );
}


const IdealCandidatesListingContainer = () => {
    const { organization } = useOrganization();
    const [idealCandidates, setIdealCandidates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [columns] = useState([
        { id: 'title', label: 'Title', align: 'left' },
        { id: 'description', label: 'Description', align: 'left' },
        { id: 'status', label: 'Status', align: 'left'}
    ]);

    const setIdealCandidatesFromAPI = (response) => {
        setIdealCandidates(response.results.map(idealCandidate => {
            return {
                id: idealCandidate.uuid,
                archivedAt: idealCandidate.archivedAt,
                values: {
                    title: (<RouterLink to={`/ideal-candidates/${idealCandidate.uuid}`}>{idealCandidate.title}</RouterLink>),
                    description: idealCandidate.description,
                    status: idealCandidate.archivedAt ? 'Archived' : 'Active'
                }
            }
        }));
    }

    const onCreate = async (values) => {
        const skillsScorecard = {
            name: 'skills',
            criteria: values.skills.map(skill => {
                return {
                    id: uuid4(),
                    label: skill.name,
                    description: skill.description,
                    weight: skill.weight,
                    levels: [{
                        label: 'None',
                        weight: 0
                    },{
                        label: '0-5',
                        weight: 1
                    }, {
                        label: '5-10',
                        weight: 2
                    }, {
                        label: '10+',
                        weight: 3
                    }]
                }
            })
        };
        const behaviorsScorecard = {
            name: 'behaviors',
            criteria: values.behaviors.map(behavior => {
                return {
                    id: uuid4(),
                    label: behavior.name,
                    description: behavior.description,
                    weight: behavior.weight,
                    levels: [{
                        label: 'No',
                        weight: 0
                    }, {
                        label: 'Somewhat',
                        weight: 1
                    }, {
                        label: 'Maybe',
                        weight: 2
                    }, {
                        label: 'Likely',
                        weight: 3
                    }, {
                        label: 'Yes',
                        weight: 4
                    }]
                }
            })
        };
        const idealCandidate = {
            title: values.title,
            description: values.description,
            scorecards: [skillsScorecard, behaviorsScorecard]
        }
        await createIdealCandidate(organization, idealCandidate);
        const response = await listIdealCandidates(organization);
        setIdealCandidatesFromAPI(response);
        posthog.capture('IdealCandidateCreated', {organization: organization.name});
    };

    useEffect(() => {
        const fetchIdealCandidates = async () => {
            setLoading(true);
            const response = await listIdealCandidates(organization, true);
            setIdealCandidatesFromAPI(response);
            setLoading(false);
        };
        fetchIdealCandidates();
    }, [organization]); // Empty dependency array ensures this runs once on mount

    if (loading) {
        return <div>Loading...</div>; // You can replace this with a proper loading component
    }

    return (
        <div>
            <Box mt={1} mb={2}>
                <IdealCandidateListingBreadcrumbs />
            </Box>
            <Divider />
            <IdealCandidatesListing idealCandidates={idealCandidates} columns={columns} onCreate={onCreate} />
        </div>
    );
};

export default IdealCandidatesListingContainer;
