import config from '../config';
import {Auth} from "aws-amplify";

import {fetchWithRetry} from ".";

export async function listPats() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/pats`;
    const response = await fetchWithRetry(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}

export async function createPat(patData) {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/pats`;
    const response = await fetchWithRetry(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(patData)
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}

export async function deletePat(patId) {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const token = cognitoUser.signInUserSession.idToken.jwtToken;
  const url = `${config.coreApi.protocol}://${config.coreApi.host}/pats/${patId}`;
  const response = await fetchWithRetry(url, {
      method: 'DELETE',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      }
  });
  if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
  }
}