import '@aws-amplify/ui-react/styles.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import awsExports from './aws-exports';
import reportWebVitals from './reportWebVitals';
import { Authenticator } from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify';
import * as Sentry from "@sentry/react";
import posthog from 'posthog-js';

import './index.css';
import useAuth from './hooks/useAuth';
import { createOrganization } from './ctapi/organizations';
import { App } from './components';
import CreateOrganizationDialog from "./components/CreateOrganizationDialog/CreateOrganizationDialog";
import { OrganizationProvider } from './shared/OrganizationContext';
import PotentialCandidateForm from './components/PotentialCandidateForm';
import {createMatchPublic, getIdealCandidatePublic} from "./ctapi/idealCandidates";
import config from './config';

if (config.posthog) {
    posthog.init(config.posthog.key, {api_host: config.posthog.host});
}

if (config.sentryEnabled) {
    Sentry.init({
        dsn: "https://78763da35da5113b5a9af8c9e1fc6378@o4505607970357248.ingest.sentry.io/4505863887978496",
        release: process.env.REACT_APP_RELEASE,
        environment: process.env.REACT_APP_ENV,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Sentry.Replay(),
        ],
        // eslint-disable-next-line no-unused-vars
        beforeSend(event, hint) {
            if (event.exception) {
                for (let exception of event.exception.values) {
                    if (exception.type === 'Error' &&
                        exception.value.includes('ResizeObserver loop')) {
                        return null;
                    }
                }
            }
            return event;
        }
    });
}

Amplify.configure({
    Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
        oauth: {
            domain: awsExports.OAUTH_DOMAIN,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: awsExports.REDIRECT_SIGN_IN,
            redirectSignOut: awsExports.REDIRECT_SIGN_OUT,
            responseType: 'code',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));


const AppRootComponent = () => {
    const { user, signOut } = useAuth();
    const [organization, setOrganization] = useState(null);

    useEffect(() => {
        if (user) {
            setOrganization(user.defaultOrganization);
        }
    }, [user]);

    const handleCreateOrganization = async (organizationDialogResult) => {
        const createOrganizationResponse = await createOrganization(organizationDialogResult);
        setOrganization(createOrganizationResponse);
    };

    return (
        <React.StrictMode>
            <Sentry.ErrorBoundary>
                <Authenticator>
                    {user && !organization && (
                        <CreateOrganizationDialog open={true} onCreate={handleCreateOrganization} />
                    )}
                    {user && organization && (
                        <OrganizationProvider value={ organization }>
                            <App user={user} signOut={signOut} />
                        </OrganizationProvider>
                    )}
                </Authenticator>
            </Sentry.ErrorBoundary>
        </React.StrictMode>
    );
};


// TODO: This should really be served as its own app,
//       but to reduce complexity, we're going to just
//       serve it in the same Amplify App initially.
const isApp = () => {
    const path = window.location.pathname;
    return !path.startsWith('/apply');
};


// TODO: Move majority of this component into a PotentialCandidateFormContainer.
const transformIdealCandidate = (ic) => {
    return {
        id: ic.uuid,
        title: ic.title,
        theme: ic.theme,
        organizationName: ic.organizationName,
        organizationDescriptiveName: ic.organizationDescriptiveName,
        skills: ic.scorecards.find(scorecard => scorecard.name === 'skills').criteria.map(skill => {
            return {
                id: skill.id,
                name: skill.label,
                description: skill.description,
                weight: skill.weight,
                levels: skill.levels
            }
        }),
        behaviors: ic.scorecards.find(scorecard => scorecard.name === 'behaviors').criteria.map(behavior => {
            return {
                id: behavior.id,
                name: behavior.label,
                description: behavior.description,
                weight: behavior.weight,
                levels: behavior.levels
            }
        })
    }
};


const PotentialCandidateRootComponent = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const [idealCandidate, setIdealCandidate] = useState(null);
    const idealCandidateUuid= queryParams.get('ic');

    const onSubmit = async (results) => {
        await createMatchPublic(idealCandidate, results);
    };

    useEffect(() => {
        const fetchIdealCandidate = async () => {
            if (!idealCandidate && idealCandidateUuid) {
                const retrievedIdealCandidate = await getIdealCandidatePublic(idealCandidateUuid);
                setIdealCandidate(transformIdealCandidate(retrievedIdealCandidate));
            }
        };
        fetchIdealCandidate();
    }, [idealCandidateUuid, idealCandidate]);
    if (idealCandidate) {
        return (
            <Sentry.ErrorBoundary>
                <PotentialCandidateForm idealCandidate={idealCandidate} onSubmit={onSubmit} />);
            </Sentry.ErrorBoundary>
        );
    }
    else {
        return (<div>Loading...</div>);
    }
};

if (isApp()) {
    root.render(<AppRootComponent />);
}
else {
    root.render(<PotentialCandidateRootComponent />);
}

reportWebVitals();
