import { Box, Grid, TextField } from "@mui/material";
import OrdinalCriterionSelector from "./OrdinalCriterionSelector";
import { getLevel } from "./helpers";

export function FormEvalSection({
  dataLabel,
  dataSet,
  formValues,
  handleUpdate,
  idealCandidate,
  label,
  viewOnly
}) {
  return (
      <>
          <h2>{label}</h2>
          {dataSet.map(x => (
              <Box marginBottom={2} key={x.name}>
                  <Grid container spacing={4}>
                      <Grid item xs={12} sm={12}>
                          <OrdinalCriterionSelector
                              name={x.name}
                              description={x.description}
                              levels={x.levels}
                              selectedLevel={formValues[x.id]?.level?.id}
                              onChange={(value) => {
                                  handleUpdate(
                                      dataLabel,
                                      x.id,
                                      'level',
                                      getLevel(idealCandidate, x.id, value, dataLabel)
                                  )
                              }}
                              disabled={viewOnly}
                          />
                      </Grid>
                      <Grid item xs={0} sm={2} />
                      <Grid item xs={12} sm={10}>
                          <TextField
                              disabled={viewOnly}
                              value={formValues[x.id]?.notes}
                              fullWidth
                              size="small"
                              label="(Optional) Tell us more."
                              onChange={(e) => handleUpdate(
                                  dataLabel,
                                  x.id,
                                  'notes',
                                  e.target.value
                              )} 
                          />
                      </Grid>
                  </Grid>
              </Box>
          ))}
      </>
  );
}