import {useEffect, useRef, useState} from "react";
import Box from '@mui/material/Box';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useFormik } from "formik";
import { Alert, Button, Checkbox, Divider, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";

import { getInitialValues, PCF_CONSTANTS } from "./helpers";
import { FormEvalSection } from './FormEvalSection';
import { FormField } from './FormField';
import { FormFieldWrapper } from './FormFieldWrapper';
import { FormHeader } from './FormHeader';
import { FormRadioGroup } from './FormRadioGroup';
import { FormSuccess } from "./FormSuccess";

export const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    paddingBottom: '56px',
    position: 'relative',
  
    '@media (min-width: 900px)': {
      paddingBottom: '32px'
    }
  });
  
  export const Image = styled.img({
    bottom: 0,
    height: '26px',
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
  
    '@media (min-width: 900px)': {
      left: 'calc(100% - 110px)',
      top: '40px'
    }
  });

const PotentialCandidateForm = ({
    idealCandidate,
    onSubmit,
    potentialCandidate,
    includeHeader,
    viewOnly,
    showSuccess
}) => {
    const headerRef= useRef(null);
    const [headerHeight, setHeaderHeight] = useState(0);
  
    useEffect(() => {
      if (headerRef.current) {
        const height = headerRef.current.offsetHeight;
        setHeaderHeight(height);
      }
    }, [])

    const [attestation, setAttestation] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [errors, setErrors] = useState(null)

    const formik = useFormik({
        initialValues: getInitialValues(idealCandidate, potentialCandidate),
        validationSchema: PCF_CONSTANTS.FORM_VALIDATION,
        onSubmit: async ({ name, email, skills, behaviors, gender, race, phone }) => {
            setErrors(null);
            const lowerEmail = email ? email.toLowerCase() : email;
            const res = await onSubmit({ name, skills, behaviors, gender, race, phone, email: lowerEmail });
            if (res?.code === 422) {
                setErrors(res.errors)
            } else {
                setCompleted(true);
            }
        }
    });

    const handleChange = (dataSet, id, label, value) => {
        const data = formik.values[dataSet];
        formik.setFieldValue(dataSet, {
            ...data,
            [id]: { ...data[id], [label]: value }
        })
    };

    function handlePhoneBlur() {
        if (!formik.touched.phone) formik.setTouched({ ...formik.touched, phone: true });

        if (!formik.values.phone || !matchIsValidTel(formik.values.phone)) {
            formik.setFieldError('phone', 'Phone is required')
        } else {
            const { phone, ...rest } = formik.errors; 
            formik.setFieldError({ ...rest });
        }
    };

    function handlePhoneChange(value) {
        if (!formik.touched.phone) formik.setTouched({ ...formik.touched, phone: true });

        formik.setFieldValue('phone', value);

        const { phone, ...rest } = formik.errors; 
        formik.setFieldError({ ...rest });
    };

    function handleValidateRequiredFields() {
        const requiredFields = ['name', 'email', 'phone'];
        return attestation && requiredFields.every(field => formik.touched[field] && !formik.errors[field]);
    }

    if (completed) {
        if(showSuccess) {
            return <FormSuccess idealCandidate={idealCandidate} includeHeader={includeHeader}/>
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ paddingTop: includeHeader ? `${headerHeight}px` : '0px' }}>
                {includeHeader && (
                    <FormHeader
                        ref={headerRef}
                        color={idealCandidate.theme?.applicationHeaderColor || "#186BCC"}
                        idealCandidate={idealCandidate}
                        logo={idealCandidate.theme?.applicationLogoUrl}
                    />
                )}
                <Box maxWidth="900px" id="form-container" pl={2} pr={2}>
                    {errors && errors.map(error => (
                        <Box marginBottom={1}>
                            <Alert severity="error">{error.loc[0]?.toUpperCase()} {error.msg.toLowerCase()}</Alert>
                        </Box>
                    ))}
                    {completed ? (
                        <FormSuccess idealCandidate={idealCandidate} includeHeader={includeHeader} />
                    ) : (
                        <>
                                                <Box marginBottom={5} id="candidate-identification-container">
                        <FormFieldWrapper label="Name">
                            <FormField formik={formik} type="name" viewOnly={viewOnly} />
                        </FormFieldWrapper>
                        <FormFieldWrapper label="Email">
                            <FormField formik={formik} type="email" viewOnly={viewOnly} />
                        </FormFieldWrapper>
                        <FormFieldWrapper label="Phone">
                            <MuiTelInput
                                defaultCountry="US"
                                required
                                size="small"
                                disabled={viewOnly}
                                value={formik.values.phone}
                                onChange={handlePhoneChange}
                                onBlur={handlePhoneBlur}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </FormFieldWrapper>
                    </Box>
                    <Divider />
                    <Box marginBottom={3} id="candidate-skills-container">
                        <FormEvalSection
                            dataLabel='skills'
                            dataSet={idealCandidate.skills}
                            formValues={formik.values.skills}
                            handleUpdate={handleChange}
                            idealCandidate={idealCandidate}
                            label='Skills'
                            viewOnly={viewOnly}
                        />
                    </Box>
                    <Divider />
                    <Box marginBottom={5} id="candidate-behaviors-container">
                        <FormEvalSection
                            dataLabel='behaviors'
                            dataSet={idealCandidate.behaviors}
                            formValues={formik.values.behaviors}
                            handleUpdate={handleChange}
                            idealCandidate={idealCandidate}
                            label='Decision Making'
                            viewOnly={viewOnly}
                        />
                    </Box>
                    <Divider />
                    <Box id="candidate-self-identification-container">
                        <h2>Voluntary Self-Identification of Ethnicity, Race and Gender</h2>
                        <Box paddingBottom={2}>
                            We are an Affirmative Action, Equal Opportunity Employer. Our employment 
                            decisions are made without regard to race, color, religion, gender, 
                            national origin, age, disability, marital status, veteran or military 
                            status, or any other legally protected status. The purpose of this 
                            Employee EEO Self-Identification Form is to comply with federal government 
                            record-keeping and reporting requirements.
                        </Box>
                        <FormRadioGroup
                            displayRow
                            dataSet={PCF_CONSTANTS.GENDER_VALUES}
                            formik={formik}
                            label="Gender"
                            value="gender"
                            viewOnly={viewOnly}
                        />
                        <FormRadioGroup
                            dataSet={PCF_CONSTANTS.RACE_VALUES}
                            formik={formik}
                            label="Race"
                            value="race"
                            viewOnly={viewOnly}
                        />
                    </Box>
                    {!viewOnly && (
                        <Container>
                            <Divider />
                            <Grid container>
                                <Grid item sm={12} md={8}>
                                    <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'row' }}>
                                    <Checkbox
                                        checked={attestation}
                                        color="primary"
                                        onChange={(e) => setAttestation(e.target.checked)}
                                    />
                                    <Box sx={{ marginLeft: '8px', marginTop: '2px' }}>
                                        <Typography variant="body2">
                                            By checking this box, I hereby attest and confirm that the answers 
                                            and information I have provided are true and accurate to the best 
                                            of my knowledge.
                                        </Typography>
                                    </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Button
                                disabled={!handleValidateRequiredFields()}
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>
                            <Image src='/assets/ct_powered_by.png' alt="Powered By Candidate Tools Logo" />
                        </Container>
                    )}
                        </>
                    )}
                </Box>
            </Box>
        </form>
    );
}

PotentialCandidateForm.defaultProps = {
    includeHeader: true,
    showSuccess: true,
    viewOnly: false,
};

export default PotentialCandidateForm;
