import React, { useState } from "react";
import CreateIdealCandidateForm from "../CreateIdealCandidateForm/CreateIdealCandidateForm";
import PotentialCandidateView from "../PotentialCandidateView/PotentialCandidateView";
import {Box, Button, Dialog, DialogActions, Divider, Grid, Link, Typography} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import ArchiveIcon from '@mui/icons-material/Archive';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import DialogContent from "@mui/material/DialogContent";
import PotentialCandidatesFilter from "./PotentialCandidatesFilter/PotentialCandidatesFilter";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import PotentialCandidateForm from "../PotentialCandidateForm";
const moment = require('moment');


function formatUTCToLocal(timestampFloat) {
    // Convert the float timestamp into a moment object
    const date = moment.unix(timestampFloat);

    // Format the date to the desired local format
    return date.format('YYYY-MM-DD hh:mmA');
}

function scoreToColor(score) {
    if (score <= 0.5) {
        return '#D0312D';
    } else if (score <= .75) {
        return '#FC6A03';
    } else {
        return '#028A0F';
    }
}


function sortBySkillScore(candidates) {
    return candidates.slice().sort((a, b) => {
        const scoreA = a.scorecardResults.find(sc => sc.scorecardName === "skills").score;
        const scoreB = b.scorecardResults.find(sc => sc.scorecardName === "skills").score;
        return scoreB - scoreA;  // For descending order (higher scores first). For ascending order, swap scoreA and scoreB.
    });
}

function formatPhoneNumber(str) {
    // Check if the string matches the pattern +1 followed by 10 digits
    if (/^\+1\d{10}$/.test(str)) {
        return str.replace(/^(\+1)(\d{3})(\d{3})(\d{4})$/, '$1 ($2) $3-$4');
    }
    return str; // return original string if it doesn't match the pattern
}


function statusLabel(status) {
    switch(status) {
        case "approved":
            return "Approved";
        case "pending":
            return "Pending";
        case "declined":
            return "Declined";
        default:
            return "Unknown";
    }
}

function statusColor(status) {
    switch(status) {
        case "approved":
            return "success";
        case "pending":
            return "primary";
        case "declined":
            return "error";
        default:
            return "warning";
    }
}


const ArchiveConfirmationDialog = ({open, onCancel, onConfirm}) => {
    return (
        <Dialog open={open}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
                <Typography>Archiving an Ideal Candidate will make it hidden by default. Don't worry. You can unarchive later if you want to.</Typography>
            </DialogContent>
            <Box pb={2} pl={2} pr={2}>
                <DialogActions>
                    <Button variant="text" color="error" onClick={onConfirm}>Archive</Button>
                    <Box flexGrow={1} />
                    <Button variant="contained" autoFocus onClick={onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

const CreatePotentialCandidateDialog = ({open, idealCandidate, onSubmit, onClose}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogTitle>Create Potential Candidate</DialogTitle>
            <DialogContent>
                <PotentialCandidateForm showSuccess={false} includeHeader={false} idealCandidate={idealCandidate} onSubmit={onSubmit} />
            </DialogContent>
        </Dialog>
    );
};


const IdealCandidateView = ({idealCandidate, potentialCandidates, onArchive, onUnarchive, onShare, onCreatePotentialCandidate, initialStatuses, initialSkillScore, initialBehaviorScore}) => {

    const [currentTab, setCurrentTab] = useState("potential-candidates");
    const [viewPotentialCandidate, setViewPotentialCandidate] = useState(null);
    const [openArchiveConfirmationDialog, setOpenArchiveConfirmationDialog] = useState(false);
    const [openCreatePotentialCandidateDialog, setOpenCreatePotentialCandidateDialog] = useState(false);

    const handleCloseViewPotentialCandidate = () => {
        setViewPotentialCandidate(null);
    }

    const handleDialogKeyDown = (e) => {
        e.stopPropagation();
    };

    const handleOpenArchiveConfirmationDialog = () => {
        setOpenArchiveConfirmationDialog(true);
    };

    const handleCancelArchiveConfirmationDialog = () => {
        setOpenArchiveConfirmationDialog(false);
    };

    const handleConfirmArchiveConfirmationDialog = async () => {
        await onArchive();
        setOpenArchiveConfirmationDialog(false);
    };

    const handleUnarchive = async () => {
        await onUnarchive();
    };

    return (
        <Box>
            <Box marginBottom={2}>
                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <h2>
                            {idealCandidate.title}
                        </h2>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} alignItems="center">
                            {onArchive && !idealCandidate.archivedAt && (
                                <Grid item>
                                    <Button variant="text" startIcon={<ArchiveIcon />} onClick={handleOpenArchiveConfirmationDialog}>
                                        Archive
                                    </Button>
                                    <ArchiveConfirmationDialog onCancel={handleCancelArchiveConfirmationDialog} onConfirm={handleConfirmArchiveConfirmationDialog} open={openArchiveConfirmationDialog} />
                                </Grid>
                            )}
                            {onUnarchive && idealCandidate.archivedAt && (
                                <Grid item>
                                    <Button variant="text" startIcon={<ArchiveIcon />} onClick={handleUnarchive}>
                                        Unarchive
                                    </Button>
                                </Grid>
                            )}
                            {onShare && (
                                <Grid item>
                                    <Button variant="outlined" startIcon={<ShareIcon />} onClick={onShare}>
                                        Share
                                    </Button>
                                </Grid>
                            )}
                            {onCreatePotentialCandidate && (
                                <Grid item>
                                    <Button variant="contained"
                                            startIcon={<AddIcon />}
                                            onClick={() => setOpenCreatePotentialCandidateDialog(true)}>
                                        Add Candidate
                                    </Button>
                                    {idealCandidate && <CreatePotentialCandidateDialog idealCandidate={idealCandidate}
                                                                                       onClose={() => setOpenCreatePotentialCandidateDialog(false)}
                                                                                       open={openCreatePotentialCandidateDialog}
                                                                                       onSubmit={onCreatePotentialCandidate}
                                    />
                                    }

                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box marginTop={2}>
                <TabContext value={currentTab}>
                    <TabList onChange={(e, v) => setCurrentTab(v)}>
                        <Tab label="Potential Candidates" value="potential-candidates" />
                        <Tab label="Details" value="details" />
                    </TabList>
                    <TabPanel value={"potential-candidates"}>
                        <Box marginBottom={2}>
                            <PotentialCandidatesFilter idealCandidateId={idealCandidate.id} initialStatuses={initialStatuses} initialSkillScore={initialSkillScore} initialBehaviorScore={initialBehaviorScore} />
                        </Box>
                        {potentialCandidates.length > 0 ? (
                        <Box>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="left">Email</TableCell>
                                            <TableCell align="left">Phone</TableCell>
                                            <TableCell align="left">Applied At</TableCell>
                                            <TableCell align="center">Skill Score</TableCell>
                                            <TableCell align="center">Behavior Score</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortBySkillScore(potentialCandidates).map((pc) => (
                                            <TableRow
                                                key={pc.candidate.email}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Link style={{ cursor: 'pointer' }} onClick={() => setViewPotentialCandidate(pc.candidate.email)}>{pc.candidate.name}</Link>
                                                    <Dialog onKeyDown={handleDialogKeyDown} maxWidth="lg" open={viewPotentialCandidate === pc.candidate.email} onClose={handleCloseViewPotentialCandidate}>
                                                        <DialogContent>
                                                            <PotentialCandidateView
                                                                idealCandidate={idealCandidate}
                                                                onClose={handleCloseViewPotentialCandidate}
                                                                potentialCandidate={pc}
                                                            />
                                                        </DialogContent>
                                                    </Dialog>
                                                </TableCell>
                                                <TableCell align="left">{pc.candidate.email}</TableCell>
                                                <TableCell align="left">{formatPhoneNumber(pc.candidate.phone)}</TableCell>
                                                <TableCell align="left">{formatUTCToLocal(pc.createdAt)}</TableCell>
                                                <TableCell align="center">
                                                    <Chip
                                                        label={`${Math.floor(pc.scorecardResults.find((sc) => sc.scorecardName === "skills").score * 100) }%`}
                                                        sx={{
                                                            backgroundColor: scoreToColor(pc.scorecardResults.find((sc) => sc.scorecardName === "skills").score),
                                                            color: 'white',
                                                            fontSize: '12px',
                                                            fontWeight: 'bold',
                                                            height: '30px',
                                                            width: '55px',
                                                            justifyContent: 'center',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Chip
                                                        label={`${Math.floor(pc.scorecardResults.find((sc) => sc.scorecardName === "behaviors").score * 100) }%`}
                                                        sx={{
                                                            backgroundColor: scoreToColor(pc.scorecardResults.find((sc) => sc.scorecardName === "behaviors").score),
                                                            color: 'white',
                                                            fontSize: '12px',
                                                            fontWeight: 'bold',
                                                            height: '30px',
                                                            width: '55px',
                                                            justifyContent: 'center',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Chip
                                                        label={statusLabel(pc.status)}
                                                        color={statusColor(pc.status)}
                                                        sx={{
                                                            color: 'white',
                                                            fontSize: '12px',
                                                            fontWeight: 'bold',
                                                            height: '30px',
                                                            justifyContent: 'center'
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        ) : "No Potential Candidates"
                    }
                    </TabPanel>
                    <TabPanel value="details">
                        <CreateIdealCandidateForm idealCandidate={idealCandidate} viewOnly />
                    </TabPanel>
                </TabContext>
            </Box>
        </Box>
    );
};

export default IdealCandidateView;
