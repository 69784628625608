import { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { usePatsContext } from "../../shared/PatContext";
import { PersonalAccessTokensModal } from "./PersonalAccessTokensModal";

export default function PersonalAccessTokensList() {
  const { handleDeletePat, handleGetPats, pats } = usePatsContext();
  useEffect(() => { handleGetPats() }, [handleGetPats]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  function handleCloseModal() {
    setShowDeleteModal(false);
  }

  async function onDelete(id) {
    const res = await handleDeletePat(id);
    if (res === 'success') setShowDeleteModal(true);
  }

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    const [datePart, timePart] = formattedDate.split(', ');
    const correctedTimePart = timePart.replace(/ /, '');
    
    return `${datePart} ${correctedTimePart}`;
}

  return (
    <>
      <PersonalAccessTokensModal isOpen={showDeleteModal} onClose={handleCloseModal}>
        <Typography variant="h6" component="h2">
          Your personal access token has been deleted.
        </Typography>
        <Button onClick={handleCloseModal} variant="contained">CLOSE</Button>
      </PersonalAccessTokensModal>

      {pats.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created At:</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pats.map(pat => (
                <TableRow key={pat.id} sx={{ '&:last-child': { '& .MuiTableCell-root': { borderBottom: 0 } } }}>
                  <TableCell>{pat.name}</TableCell>
                  <TableCell>{formatTimestamp(pat.createdAt)}</TableCell>
                  <TableCell>
                    <Button
                      aria-label="delete"
                      color="error"
                      onClick={() => onDelete(pat.id)}
                      variant="outlined"
                    >
                      DELETE
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" component="h2" sx={{ textAlign: 'center' }}>
          You currently do not have any PATs assigned. Start by creating one below.
        </Typography>
      )}
    </>
  );
}