import { TextField } from "@mui/material";

export function FormField({ formik, type, viewOnly }) {
  return (
      <TextField
          type={type}
          disabled={viewOnly}
          fullWidth
          required
          size="small"
          name={type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched[type] && Boolean(formik.errors[type])}
          helperText={formik.touched[type] && formik.errors[type]}
          value={formik.values[type]}
      />
  );
}