import React, { useState } from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CreateIdealCandidateForm from '../CreateIdealCandidateForm/CreateIdealCandidateForm';
import {FormControlLabel, FormGroup, Switch} from "@mui/material";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const IdealCandidatesListing = ({ idealCandidates, columns, onCreate }) => {
    const [open, setOpen] = useState(false);
    const [includeArchived, setIncludeArchived] = useState(false);

    const handleCreateIdealCandidateOpen = () => {
        setOpen(true);
    };

    const handleCreateIdealCandidateClose = () => {
        setOpen(false);
    };

    const handleIncludeArchivedChange = (event) => {
        setIncludeArchived(event.target.checked);
    }

    const handleCreateIdealCandidate = (values) => {
        const result = onCreate(values);
        if (result instanceof Promise) {
            result.then(() => {
                setOpen(false);
            }).catch(e => {
                console.error("Error in onCreate:", e);
            });
        } else {
            setOpen(false);
        }
    };

    const dialog = (
        <Dialog open={open} onClose={handleCreateIdealCandidateClose} fullScreen TransitionComponent={Transition}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography p={1} variant="h4">Create an Ideal Candidate</Typography>
                    <Divider />
                    <Box flexGrow={1}></Box>
                    <IconButton onClick={handleCreateIdealCandidateClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <CreateIdealCandidateForm onSubmit={handleCreateIdealCandidate} />
            </DialogContent>
        </Dialog>
    );

    let listing;
    const filteredIdealCandidates = idealCandidates.filter(idealCandidate => {
        return includeArchived || !idealCandidate.archivedAt;
    });
    if (!filteredIdealCandidates || filteredIdealCandidates.length === 0) {
        listing = (
            <Box p={1} paddingTop={3}>
                <Typography variant="h5">No ideal candidates found.</Typography>
            </Box>
        );
    }
    else {
        listing = (
            <Box p={1}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell align={column.align || "center"} key={column.id}>
                                        <Typography>{column.label}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredIdealCandidates.map((idealCandidate) => (
                                <TableRow
                                    key={idealCandidate.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {columns.map((column) => (
                                        <TableCell align={column.align || "center"} key={column.id}>
                                            <Typography sx={{
                                                display: "block",
                                                maxWidth: "500px",
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'}}>
                                                {idealCandidate.values[column.id]}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
    return (
        <Box mt={1}>
            {dialog}
            <Box mt={2}>
                <Box p={1} paddingBottom={3} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">Ideal Candidates</Typography>
                    <Button variant="contained" onClick={handleCreateIdealCandidateOpen}>
                        Create
                    </Button>
                </Box>
                <Divider />
                <Box ml={2} mt={1}>
                    <FormGroup>
                        <FormControlLabel control={<Switch onChange={handleIncludeArchivedChange} />} label="Show Archived" />
                    </FormGroup>
                </Box>
                <Box mt={2}>
                    { listing }
                </Box>
            </Box>
        </Box>
    );
};

export default IdealCandidatesListing;
