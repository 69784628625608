import PotentialCandidateForm from "../PotentialCandidateForm";
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import {updateMatchStatus} from "../../ctapi/idealCandidates";
import {useOrganization} from "../../shared/OrganizationContext";
import { Close } from "@mui/icons-material";

const PotentialCandidateView = ({
    idealCandidate,
    onClose,
    potentialCandidate
}) => {

    const { organization } = useOrganization();

    const onDecline = async () => {
        try {
            await updateMatchStatus(organization, idealCandidate.id, potentialCandidate.id, "declined");
            window.location.href = `/ideal-candidates/${idealCandidate.id}`;
        } catch (error) {
            console.log(error);
        }
    };

    const onApprove = async () => {
        try {
            await updateMatchStatus(organization, idealCandidate.id, potentialCandidate.id, "approved");
            window.location.href = `/ideal-candidates/${idealCandidate.id}`;
        } catch (error) {
            console.log(error);
        }
    };

    function displayAsPercent(value) {
        return Math.floor(value * 100) + '%';
    }

    function scoreToColor(score) {
        if (score <= 0.5) {
            return '#D0312D';
        } else if (score <= .75) {
            return '#FC6A03';
        } else {
            return '#028A0F';
        }
    }

    return (
        <Box>
            <Box pb={2}>
                <Grid container alignItems="flex-end" justifyContent="space-between">
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                        {potentialCandidate.scorecardResults?.map(result => (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px' }}>
                                <Typography variant="overline" component="span" sx={{ fontSize: '11px', fontWeight: 500, letterSpacing: '1px', lineHeight: 1 }}>
                                    {result.scorecardName === 'skills' ? 'Skills Score' : 'Behavior Score'}
                                </Typography>
                                <Typography variant='h6' component="span" sx={{ color: scoreToColor(result.score), fontWeight: 700, lineHeight: 1, paddingLeft: '1px' }}>
                                    {displayAsPercent(result.score)}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <Button onClick={onDecline} variant="text">Decline</Button>
                        <Button onClick={onApprove} variant="contained">Approve</Button>
                        <Divider orientation="vertical" flexItem />
                        <Button onClick={onClose} variant="text" endIcon={<Close />}>Close</Button>
                    </Box>
                </Grid>
            </Box>
            <Divider />
            <Box pt={5}>
                <PotentialCandidateForm idealCandidate={idealCandidate}
                                        potentialCandidate={potentialCandidate}
                                        includeHeader={false}
                                        viewOnly={true}
                />
            </Box>
        </Box>
    );
}

export default PotentialCandidateView;
