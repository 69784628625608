import config from '../config';

import {fetchWithRetry} from ".";


export async function getCurrentUser(idToken) {
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/current-user?includeInternal=true`;
    const response = await fetchWithRetry(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${idToken}`,
        },
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}
