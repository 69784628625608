import {
  Box,
  Container,
  Modal
} from "@mui/material";

const BoxStyle = {
  alignItems: 'center',
  backgroundColor: 'white',
  boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '32px',
  width: '100%'
};

const ContainerStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center'
};

export function PersonalAccessTokensModal({
  children,
  isOpen,
  onClose
}) {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Container maxWidth="sm" sx={ContainerStyle}>
        <Box sx={BoxStyle}>
          {children}
        </Box>
      </Container>
    </Modal>
  );
}
