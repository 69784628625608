import { Box, Typography } from "@mui/material";
import { FormHeader } from "./FormHeader";


export function FormSuccess({ idealCandidate, includeHeader }) {
  return (
    <Box sx={{ paddingTop: '240px' }} display="flex" flexDirection="column" alignItems="center">
        {includeHeader && (
            <FormHeader
                color={idealCandidate.theme?.applicationHeaderColor || "#186BCC"}
                idealCandidate={idealCandidate}
                logo={idealCandidate.theme?.applicationLogoUrl}
            />
        )}
        <Box pl={2} pr={2}>
            <Typography component="h2" variant="h2">Application submitted!</Typography>
            <Typography sx={{ textAlign: 'center' }}>You may close this browser window.</Typography>
        </Box>
    </Box>
  );
}