export async function fetchWithRetry(url: string, options: RequestInit, retries = 1): Promise<Response> {
    for (let i = 0; i <= retries; i++) {
        const response = await fetch(url, options);
        if (response.status >= 500 && response.status < 600) {
            if (i === retries) {
                throw new Error(`HTTP error after ${retries + 1} tries! status: ${response.status}`);
            }
        } else {
            return response;
        }
    }
    throw new Error('fetchWithRetry failed without providing a response.');
}
