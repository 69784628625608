import { useEffect, useState } from 'react';
import { Hub, Auth } from 'aws-amplify';
import { getCurrentUser } from '../ctapi/users';

export const useAuth = () => {
    const [user, setUser] = useState(null);

    const signOut = async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out:', error);
        }
    };

    useEffect(() => {
        const checkUser = async () => {
            try {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                const { idToken: { jwtToken } } = cognitoUser.signInUserSession;
                const user = await getCurrentUser(jwtToken);
                setUser(user);
            } catch {
                setUser(null);
            }
        };

        // Check user state on component mount
        checkUser();

        const subscription = Hub.listen('auth', ({ payload: { event } }) => {
            switch (event) {
                case 'signIn':
                case 'signOut':
                    checkUser();
                    break;
                default:
                    break;
            }
        });

        // Clean up subscription on unmount
        return () => subscription();
    }, []);

    return { user, signOut };
};

export default useAuth;
