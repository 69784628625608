import {
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import {
  createPat,
  deletePat,
  listPats
} from '../../ctapi/pats';

export const PatsContext = createContext();

export function PatsProvider({ children, storybookValues }) {
  const [pats, setPats] = useState([]);

  async function handleCreatePat(patData) {
    try {
      const res = await createPat(patData);
      setPats([ ...pats, { createdAt: res.createdAt, id: res.id, name: res.name } ])

      return {
        result: 'success',
        token: res.token
      };
    } catch (e) {
      console.warn(e);
    }
  }

  async function handleDeletePat(patId) {
    try {
      await deletePat(patId);
      const updatedPatList = pats.filter(pat => pat.id !== patId);
      setPats(updatedPatList);

      return 'success';
    } catch (e) {
      console.warn(e);
    }
  }

  const handleGetPats = useCallback(
    async function() {
      try {
        const res = await listPats();
        setPats(res.results);
      } catch (e) {
        console.warn(e);
      }
    }, 
    [setPats]
  );

  return (
    <PatsContext.Provider value={{
      handleCreatePat,
      handleDeletePat,
      handleGetPats,
      pats,
      ...storybookValues
    }}>
      {children}
    </PatsContext.Provider>
  );
};

export function usePatsContext() {
  return useContext(PatsContext);
};
 