import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import { object, string } from 'yup';


function convertToUrlFriendlyString(input) {
    return input
        .toLowerCase()  // Convert to lower case
        .replace(/[^\w\s]|_/g, "")  // Remove non-alphanumeric or whitespace characters
        .replace(/\s+/g, "-")  // Replace whitespace with hyphens
        .replace(/^[^a-z]+|[^\da-z]+$/g, "");  // Ensure string starts and ends with a letter
}


const CreateOrganizationDialog = ({ onCreate, open }) => {

    return (
        <Dialog fullWidth maxWidth="md" open={open}>
            <Formik
                initialValues={{ descriptiveName: "", description: "" }}
                validationSchema={object({
                    descriptiveName: string()
                        .trim('Name cannot be only spaces')
                        .min(5, 'Name should be at least 5 characters')
                        .required('Name is required'),
                    description: string()
                        .trim('Description cannot be only spaces')
                        .min(5, 'Description should be at least 5 characters')
                        .required('Description is required'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    onCreate({
                        name: convertToUrlFriendlyString(values.descriptiveName),
                        descriptiveName: values.descriptiveName,
                        description: values.description
                    }); // pass form data to onCreate
                    setSubmitting(false);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <Box p={3}>
                            <Typography p={1} variant="h4">Welcome to Candidate Tools!</Typography>
                            <Typography p={1}>Let's get started by creating your Organization.</Typography>
                            <Typography p={1} variant="h6">Organization Name</Typography>
                            <Typography p={1}>What should we call your organization?</Typography>
                            <Box p={1}>
                                <Field as={TextField} fullWidth required id="descriptiveName" name="descriptiveName" label="Name" variant="outlined" helperText={touched.name ? errors.name : ""} error={touched.name && Boolean(errors.name)} />
                            </Box>
                            <Typography p={1} variant="h6">Organization Description</Typography>
                            <Typography p={1}>Tell us a little bit about your organization.</Typography>
                            <Typography p={1}>Later on, we'll be able to use this information to automate the creation and fine-tuning of your Positions, Ideal Candidates, and more! The more information you give us about what your organization does and about its purpose, the more we can help.</Typography>
                            <Box p={1}>
                                <Field as={TextField} fullWidth multiline rows={5} id="description" name="description" label="Description" variant="outlined" helperText={touched.description ? errors.description : ""} error={touched.description && Boolean(errors.description)} />
                            </Box>
                            <Box textAlign="right" p={1}>
                                <Button type="submit" variant="contained" color="primary">Create Organization</Button>
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CreateOrganizationDialog;