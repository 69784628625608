import React from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Button, Box, Divider, Grid, TextField, Typography } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';

const initialValues = {
    title: '',
    description: '',
    skills: [
        { name: '', description: '', weight: 1, },
    ],
    behaviors: [
        { name: '', description: '', weight: 1, },
    ],
};

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    description: Yup.string().max(500, 'Description must be 500 characters or less'),
    skills: Yup.array()
        .of(Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string(),
            weight: Yup.number().integer("Weight must be an integer").typeError('Weight must be a number').required('Required'),
        }))
        .required('Must have skills'),
    behaviors: Yup.array()
        .of(Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string(),
            weight: Yup.number().integer("Weight must be an integer").typeError('Weight must be a number').required('Required'),
        }))
        .required('Must have behaviors'),
});

const CreateIdealCandidateForm = ({onSubmit, idealCandidate = initialValues, viewOnly= false}) => {
    return (
        <Box>
            <Formik
                initialValues={idealCandidate}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, touched, errors, submitForm, isSubmitting }) => (
                    <Form autoComplete="off">
                        <Box pl={1} pb={3}>
                            <Box pt={1} pb={1}>
                                <Field disabled={viewOnly} as={TextField} size="small" name="title" label="Title" inputProps={{ maxLength: 100 }} helperText={<ErrorMessage name="title" />} error={touched.title && Boolean(errors.title)} />
                            </Box>
                            <Box>
                                <Field disabled={viewOnly} as={TextField} multiline fullWidth rows={4} inputProps={{ maxLength: 500 }}  size="small" name="description" label="Description" helperText={<ErrorMessage name="description" />} error={touched.description && Boolean(errors.description)} />
                            </Box>
                        </Box>
                        <Divider />
                        <FieldArray name="skills" render={arrayHelpers => (
                            <Box paddingBottom={1}>
                                <Typography p={1} variant="h6">Skills</Typography>
                                {values.skills.map((skill, index) => (
                                    <Grid p={1} container spacing={2} key={index}>
                                        <Grid item xs={3}>
                                            <Field disabled={viewOnly} as={TextField} size="small" inputProps={{ maxLength: 500 }} fullWidth name={`skills[${index}].name`} label="Name" helperText={<ErrorMessage name={`skills[${index}].name`} />} error={touched.skills?.[index]?.name && Boolean(errors.skills?.[index]?.name)} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Field disabled={viewOnly} as={TextField} size="small" inputProps={{ maxLength: 500 }} fullWidth name={`skills[${index}].description`} label="Description" helperText={<ErrorMessage name={`skills[${index}].description`} />} error={touched.skills?.[index]?.description && Boolean(errors.skills?.[index]?.description)} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Field disabled={viewOnly} as={TextField} size="small" inputProps={{ maxLength: 5 }} fullWidth name={`skills[${index}].weight`} label="Weight" helperText={<ErrorMessage name={`skills[${index}].weight`} />} error={touched.skills?.[index]?.weight && Boolean(errors.skills?.[index]?.weight)} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            {!viewOnly &&
                                            <Button onClick={() => arrayHelpers.remove(index)}>
                                                <DeleteForeverOutlinedIcon />
                                            </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                ))}
                                <div>
                                    {!viewOnly &&
                                    <Button onClick={() => arrayHelpers.push({ name: '', description: '', weight: 1 })}>
                                        <AddIcon />
                                    </Button>
                                    }
                                </div>
                            </Box>
                        )} />
                        <Divider />
                        <FieldArray name="behaviors" render={arrayHelpers => (
                            <Box paddingBottom={1}>
                                <Typography p={1} variant="h6">Behaviors</Typography>
                                {values.behaviors.map((behavior, index) => (
                                    <Grid p={1} container spacing={2} key={index}>
                                        <Grid item xs={3}>
                                            <Field disabled={viewOnly} as={TextField} size="small" inputProps={{ maxLength: 500 }} fullWidth name={`behaviors[${index}].name`} label="Name" helperText={<ErrorMessage name={`behaviors[${index}].name`} />} error={touched.behaviors?.[index]?.name && Boolean(errors.behaviors?.[index]?.name)} />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Field disabled={viewOnly} as={TextField} size="small" inputProps={{ maxLength: 500 }} fullWidth name={`behaviors[${index}].description`} label="Description" helperText={<ErrorMessage name={`behaviors[${index}].description`} />} error={touched.behaviors?.[index]?.description && Boolean(errors.behaviors?.[index]?.description)} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Field disabled={viewOnly} as={TextField} size="small" inputProps={{ maxLength: 5 }} fullWidth name={`behaviors[${index}].weight`} label="Weight" helperText={<ErrorMessage name={`behaviors[${index}].weight`} />} error={touched.behaviors?.[index]?.weight && Boolean(errors.behaviors?.[index]?.weight)} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            {!viewOnly &&
                                            <Button onClick={() => arrayHelpers.remove(index)}>
                                                <DeleteForeverOutlinedIcon />
                                            </Button>
                                            }
                                        </Grid>
                                    </Grid>
                                ))}
                                <div>
                                    {!viewOnly &&
                                    <Button disabled={viewOnly} onClick={() => arrayHelpers.push({ name: '', description: '', weight: 1 })}>
                                        <AddIcon />
                                    </Button>
                                    }
                                </div>
                            </Box>
                        )} />
                        <Box display="flex" justifyContent="flex-end">
                            {!viewOnly && <Button disabled={isSubmitting} onClick={submitForm} variant="contained" color="primary">Create</Button>}
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};


CreateIdealCandidateForm.defaultProps = {
    onSubmit: (values) => {
        console.log(values);
    }
};

export default CreateIdealCandidateForm;
