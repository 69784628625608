import { Box, Container, Typography } from "@mui/material";
import { PatsProvider } from "../../shared/PatContext";
import PersonalAccessTokensForm from "./PersonalAccessTokensForm";
import PersonalAccessTokensList from "./PersonalAccessTokensList";

const BoxStyle = {
  boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  padding: '24px',
  width: '100%'
};

const ContainerStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 128px)',
  justifyContent: 'center'
};

export default function PersonalAccessTokensContainer({ storybookValues }) {
  return (
    <PatsProvider storybookValues={storybookValues}>
      <Container maxWidth="md" sx={ContainerStyle}>
        <Typography variant="h3" component="h1">
          Personal Access Tokens
        </Typography>
        <Box sx={{ ...BoxStyle, margin: '48px 0px 24px' }}>
          <PersonalAccessTokensList />
        </Box>
        <Box sx={BoxStyle}>
          <PersonalAccessTokensForm />
        </Box>
      </Container>
    </PatsProvider>
  );
}