import * as Yup from 'yup';

export const GENDER_VALUES = [
  {
      label: 'Prefer Not to Say',
      value: 'prefer-not-to-say'
  },
  {
      label: 'Female',
      value: 'female'
  },
  {
      label: 'Male',
      value: 'male'
  },
  {
      label: 'Non-Binary',
      value: 'non-binary'
  },
  {
      label: 'Not Listed',
      value: 'not-listed'
  }
];

export const RACE_VALUES = [
  {
      label: 'Prefer Not to Say',
      value: 'prefer-not-to-say'
  },
  {
      label: 'American Indian or Alaska Native',
      value: 'american-indian-or-alaska-native'
  },
  {
      label: 'Asian',
      value: 'asian'
  },
  {
      label: 'Black or African American',
      value: 'black-or-african-american'
  },
  {
      label: 'Hispanic or Latino',
      value: 'hispanic-or-latino'
  },
  {
      label: 'Native Hawaiian or Other Pacific Islander',
      value: 'native-hawaiian-or-other-pacific-islander'
  },
  {
      label: 'White',
      value: 'white'
  },
  {
      label: 'Two or More Races',
      value: 'two-or-more-races'
  },
  {
      label: 'Other',
      value: 'other'
  }
];

export const FORM_VALIDATION = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
        .email('Must be a valid email address')
        .matches(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            'Must be a valid email address'
        )
        .required('Email is required')
})