const dev = {
    REGION: "us-east-2",
    OAUTH_DOMAIN: 'https://auth.ct-development.net',
    USER_POOL_ID: "us-east-2_Z5Sshirtg",
    USER_POOL_APP_CLIENT_ID: "48du6smm9u4svki9v4elv2a4lt",
    REDIRECT_SIGN_IN: "http://localhost:3000/oauth/callback",
    REDIRECT_SIGN_OUT: "http://localhost:3000/logout",
};

const staging = {
    REGION: "us-east-2",
    OAUTH_DOMAIN: "https://auth.ct-staging.net",
    USER_POOL_ID: "us-east-2_oppEqeZZB",
    USER_POOL_APP_CLIENT_ID: "33ntpa884oe172o0pitvktgbdn",
    REDIRECT_SIGN_IN: "https://app.ct-staging.net/oauth/callback",
    REDIRECT_SIGN_OUT: "https://app.ct-staging.net/logout"
};

const production = {
    REGION: "us-east-2",
    OAUTH_DOMAIN: 'https://auth.ct-production.net',
    USER_POOL_ID: "us-east-2_SnvTya0WS",
    USER_POOL_APP_CLIENT_ID: "360v2eeb98is6ts6f36vg4asgj",
    REDIRECT_SIGN_IN: "https://app.ct-production.net/oauth/callback",
    REDIRECT_SIGN_OUT: "https://app.ct-production.net/logout"
};

const config = process.env.REACT_APP_ENV === 'production'
    ? production
    : process.env.REACT_APP_ENV === 'staging'
    ? staging
    : dev;

export default config;
