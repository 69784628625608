export function getCandidateSkills(idealCandidate, potentialCandidate, label) {
  return idealCandidate[label]?.reduce((initialState, data) => {
      const dataResult = potentialCandidate?.scorecardResults.find(res => res.scorecardName === label)?.criteria.find(c => c.id === data.id);
      const level = dataResult
          ? data.levels.find(level => level.id === dataResult.value)
          : data.levels[0];
      const notes = dataResult
          ? dataResult.notes
          : "";
      initialState[data.id] = {
          id: data.id,
          name: data.name,
          level,
          notes
      };
      return initialState;
  }, {});
}
