import React from 'react';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from '../Header/Header';
import IdealCandidatesListingContainer from "../IdealCandidatesListing/IdealCandidatesListingContainer";
import IdealCandidateViewContainer from "../IdealCandidateView/IdealCandidateViewContainer";
import PersonalAccessTokensContainer from '../PersonalAccessTokens/PersonalAccessTokensContainer';

function App({ signOut, user }) {
    return (
        <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'grey.200' }}>
                <Header id="header-container" user={user} signOut={signOut} />
                <Box id="main-viewport" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', width: '100%', margin: '0 auto', backgroundColor: 'white', pt: 1, pr: 5, pb: 5, pl: 5 }}>
                    <Routes>
                        <Route path="/" element={<IdealCandidatesListingContainer />} />
                        <Route path="/ideal-candidates" element={<IdealCandidatesListingContainer />} />
                        <Route path="/ideal-candidates/:idealCandidateUuid" element={<IdealCandidateViewContainer />} />
                        <Route path="/pats" element={<PersonalAccessTokensContainer />} />
                    </Routes>
                </Box>
            </Box>
        </Router>
    );
}

export default App;
