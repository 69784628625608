import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";

export function FormRadioGroup({
  dataSet,
  displayRow,
  formik,
  label,
  value,
  viewOnly
}) {
  return (
      <Box paddingBottom={2} sx={{ ".MuiFormControlLabel-label": { fontSize: "0.9rem" } }}>
          <FormControl disabled={viewOnly}>
              <Typography fontWeight="bold">{label}</Typography>
              <RadioGroup
                  row={displayRow || false}
                  defaultValue="prefer-not-to-say"
                  name="race-radio-buttons-group"
                  onChange={(e) => formik.setFieldValue(value, e.target.value)}
                  value={formik.values[value]}
              >
                  {dataSet.map(x => (
                      <FormControlLabel
                          key={x.value}
                          value={x.value}
                          control={<Radio size="small"/>}
                          label={x.label}
                      />
                  ))}
              </RadioGroup>
          </FormControl>
      </Box>
  );
}