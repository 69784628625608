import config from '../config';
import {Auth} from "aws-amplify";

import {fetchWithRetry} from ".";


export async function createOrganization({name, descriptiveName, description}) {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations`;
    const response = await fetchWithRetry(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({name, descriptiveName, description})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}
