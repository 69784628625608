import config from '../config';
import {Auth} from "aws-amplify";

import {fetchWithRetry} from ".";


interface Organization {
    name: string;
}

interface IdealCandidate {
    id: string;
    [key: string]: any; // for flexibility
}

interface Results {
    name: string;
    email: string;
    phone: string;
    race: string;
    gender: string;
    skills: Record<string, any>;
    behaviors: Record<string, any>;
}

export async function listIdealCandidates(organization: Organization, includeArchived: boolean): Promise<any> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const parameters = {
        includeArchived: includeArchived ? 'true' : 'false'
    }
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations/${organization.name}/ideal-candidates?${new URLSearchParams(parameters)}`;
    const response = await fetchWithRetry(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}

export async function createIdealCandidate(organization: Organization, idealCandidate: IdealCandidate): Promise<any> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations/${organization.name}/ideal-candidates`;
    const response = await fetchWithRetry(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(idealCandidate)
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}


export async function getIdealCandidate(organization: Organization, idealCandidateUuid: string): Promise<any> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations/${organization.name}/ideal-candidates/${idealCandidateUuid}`;
    const response = await fetchWithRetry(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}


export async function listMatches(organization: Organization, idealCandidateUuid: string): Promise<any> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations/${organization.name}/ideal-candidates/${idealCandidateUuid}/matches`;
    const response = await fetchWithRetry(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}


export async function getIdealCandidatePublic(idealCandidateUuid: string): Promise<any> {
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/ideal-candidates/${idealCandidateUuid}`;
    const response = await fetchWithRetry(url, {
        method: 'GET'
    });
    if (!response.ok) {
        const msg = `Error fetching list of ideal candidates: ${response.status}`
        console.error(msg);
        throw new Error(msg);
    }
    return await response.json();
}


export async function createMatchPublic(idealCandidate: IdealCandidate, results: Results): Promise<any> {
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/ideal-candidates/${idealCandidate.id}/matches`;
    const body = {
        candidate: {
            name: results.name,
            email: results.email,
            phone: results.phone,
            race: results.race,
            gender: results.gender,
        },
        scorecard_results: [{
            scorecard_name: "skills",
            version: "1",
            criteria: Object.values(results.skills).map(skill => ({
                id: skill.id,
                value: skill.level.id,
                notes: skill.notes
            }))
        },{
            scorecard_name: "behaviors",
            version: "1",
            criteria: Object.values(results.behaviors).map(behavior => ({
                id: behavior.id,
                value: behavior.level.id,
                notes: behavior.notes
            }))
        }]
    };
    const response = await fetchWithRetry(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    if (!response.ok) {
        const msg = `Error submitting match.`
        console.error(msg);
        throw new Error(msg);
    }
    return response;
}


export async function updateMatchStatus(organization: Organization, idealCandidateUuid: string, matchUuid: string, status: string): Promise<any> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations/${organization.name}/ideal-candidates/${idealCandidateUuid}/matches/${matchUuid}`;
    const response = await fetchWithRetry(url, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"status": status})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}


export async function archiveIdealCandidate(organization: Organization, idealCandidateUuid: string): Promise<any> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations/${organization.name}/ideal-candidates/${idealCandidateUuid}/archive`;
    const response = await fetchWithRetry(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}


export async function unarchiveIdealCandidate(organization: Organization, idealCandidateUuid: string): Promise<any> {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const token = cognitoUser.signInUserSession.idToken.jwtToken;
    const url = `${config.coreApi.protocol}://${config.coreApi.host}/organizations/${organization.name}/ideal-candidates/${idealCandidateUuid}/unarchive`;
    const response = await fetchWithRetry(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
}