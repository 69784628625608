import React, { useState } from 'react';
import {Menu, MenuItem, Checkbox, Button, Box, Slider} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

const statuses = {
    pending: 'Pending',
    approved: 'Approved',
    declined: 'Declined',
}

export default function PotentialCandidatesFilter({idealCandidateId, initialStatuses, initialSkillScore, initialBehaviorScore}) {

    const [selectedStatuses, setSelectedStatuses] = useState(initialStatuses);
    const [skillsValue, setSkillsValue] = useState(initialSkillScore); // Initial value for Skills slider
    const [behaviorsValue, setBehaviorsValue] = useState(initialBehaviorScore); // Initial value for Behaviors slider

    const [statusAnchorEl, setStatusAnchorEl] = useState(null);
    const [skillsAnchorEl, setSkillsAnchorEl] = useState(null);
    const [behaviorsAnchorEl, setBehaviorsAnchorEl] = useState(null);

    const onApply = () => {
        // NOTE: You may need to adjust this URL if you intend to use the slider values in the query parameters.
        window.location.href = `/ideal-candidates/${idealCandidateId}?statuses=${selectedStatuses.join(',')}&behaviorScore=${behaviorsValue}&skillScore=${skillsValue}`;
    };

    const onReset = () => {
        // NOTE: You may need to adjust this URL if you intend to use the slider values in the query parameters.
        window.location.href = `/ideal-candidates/${idealCandidateId}?statuses=approved,pending&behaviorScore=0&skillScore=0`;
    };


    const handleSliderChange = (event, newValue, type) => {
        if (type === 'skills') {
            setSkillsValue(newValue);
        } else if (type === 'behaviors') {
            setBehaviorsValue(newValue);
        }
    };

    const handleStatusClick = (event) => {
        setStatusAnchorEl(event.currentTarget);
    };

    const handleSkillsClick = (event) => {
        setSkillsAnchorEl(event.currentTarget);
    };

    const handleBehaviorsClick = (event) => {
        setBehaviorsAnchorEl(event.currentTarget);
    };

    const handleCloseStatus = () => {
        setStatusAnchorEl(null);
    };

    const handleCloseSkills = () => {
        setSkillsAnchorEl(null);
    };

    const handleCloseBehaviors = () => {
        setBehaviorsAnchorEl(null);
    };

    const handleToggle = (value) => {
        const currentIndex = selectedStatuses.indexOf(value);
        const newChecked = [...selectedStatuses];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedStatuses(newChecked);
    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={2}>
                {/* Status Menu */}
                <Button variant="outlined" onClick={handleStatusClick} endIcon={<ArrowDropDown />}>
                    Status
                </Button>
                <Menu
                    anchorEl={statusAnchorEl}
                    open={Boolean(statusAnchorEl)}
                    onClose={handleCloseStatus}
                >
                    {['approved', 'declined', 'pending'].map((status) => (
                        <MenuItem key={status} onClick={() => handleToggle(status)}>
                            <Checkbox checked={selectedStatuses.indexOf(status) > -1} />
                            {statuses[status]}
                        </MenuItem>
                    ))}
                </Menu>

                {/* Skills Menu */}
                <Button variant="outlined" onClick={(e) => handleSkillsClick(e)} endIcon={<ArrowDropDown />}>
                    Min Skill Score: {skillsValue}
                </Button>
                <Menu
                    anchorEl={skillsAnchorEl}
                    open={Boolean(skillsAnchorEl)}
                    onClose={handleCloseSkills}
                >
                    <Box width={275} pl={1} pr={2}>
                        <MenuItem>
                            <Slider marks={[{value: 0, label: '0%'}, {value: 100, label: '100%'}]} value={skillsValue} onChange={(e, val) => handleSliderChange(e, val, 'skills')} step={5} min={0} max={100} />
                        </MenuItem>
                    </Box>
                </Menu>

                {/* Behaviors Menu */}
                <Button variant="outlined" onClick={(e) => handleBehaviorsClick(e)} endIcon={<ArrowDropDown />}>
                    Min Behavior Score: {behaviorsValue}
                </Button>
                <Menu
                    anchorEl={behaviorsAnchorEl}
                    open={Boolean(behaviorsAnchorEl)}
                    onClose={handleCloseBehaviors}
                >
                    <Box width={275} pl={1} pr={2}>
                        <MenuItem>
                            <Slider marks={[{value: 0, label: '0%'}, {value: 100, label: '100%'}]} value={behaviorsValue} onChange={(e, val) => handleSliderChange(e, val, 'behaviors')} step={5} min={0} max={100} />
                        </MenuItem>
                    </Box>
                </Menu>
            </Box>
            <Box display="flex" gap={2}>
                <Button mr={5} onClick={onReset}>Reset Filters</Button>
                <Button variant="contained" onClick={onApply}>Apply Filters</Button>
            </Box>
            </Box>
        </div>
    );
}
