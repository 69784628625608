module.exports = {
    sentryEnabled: true,
    coreApi: {
        protocol: 'https',
        host: 'api.ct-staging.net'
    },
    posthog: {
        key: 'phc_qw80Nr10bni7uTjPKscnohSDsYwR8aVHjV0Q7qaOgXo',
        host: 'https://app.posthog.com'
    }
};
