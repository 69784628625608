import { createContext, useContext, useState } from 'react';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ value, children }) => {
    const [organization, setOrganization] = useState(value);

    return (
        <OrganizationContext.Provider value={{ organization, setOrganization }}>
            {children}
        </OrganizationContext.Provider>
    );
};

export const useOrganization = () => {
    const context = useContext(OrganizationContext);
    if (context === undefined) {
        throw new Error('useOrganization must be used within an OrganizationProvider');
    }
    return context;
};
