import { Grid, Typography } from "@mui/material";

export function FormFieldWrapper({ children, label }) {
  return (
      <Grid marginBottom={2} container spacing={2} alignItems="center">
          <Grid item xs={12} sm={1}>
              <Typography variant="body1" fontWeight="bold" align="left">
                  {label}
              </Typography>
          </Grid>
          <Grid item xs={12} sm={4} >
              {children}
          </Grid>
      </Grid>
  );
}