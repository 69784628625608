import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import React from 'react';

export const FormHeader = React.forwardRef((props, ref) => (
    <Box ref={ref} marginBottom={2} width="100%" sx={{ backgroundColor: 'white', position: 'fixed', top: 0, zIndex: 10 }}>
        <AppBar position="static" elevation={0} sx={{backgroundColor: props.color}}>
            <Box p={2} align="center">
                {props.logo ? (
                    <img src={props.logo} alt="Candidate Tools Logo" style={{height: '63px'}} />
                ) : (
                    <h2>{props.idealCandidate.organizationDescriptiveName}</h2>
                )}
            </Box>
        </AppBar>
        <Box maxWidth="900px" id="form-container" sx={{ margin: '0 auto', padding: '0px 16px' }}>
            <h2>{props.idealCandidate.title} Candidate</h2>
        </Box>
    </Box>
  ));