import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Avatar,
    Box,
    Menu,
    MenuItem,
    Tooltip,
    Divider,
    ListItemIcon,
    Typography, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import Logout from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import {useOrganization} from "../../shared/OrganizationContext";

const Header = ({user, signOut}) => {
    const { organization, setOrganization } = useOrganization();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        signOut();
        handleClose();
    };

    const switchOrganization = (organization) => {
        setOrganization(organization);
        handleClose();
        navigate('/');
    }

    return (
        <AppBar position="static" elevation={0}>
            <Toolbar>
                <Box display='flex' flexGrow={1}>
                    <Link to="/"> {/* Wrap the IconButton with the Link component */}
                        <IconButton edge="start" color="inherit" aria-label="logo">
                            <img src="/ct_logo.png" alt="Candidate Tools Logo" style={{height: '40px'}}/>
                        </IconButton>
                    </Link>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box pr={2}>
                        <Button variant="text" component={Link} to="/ideal-candidates" style={{color: "white"}}>
                            Ideal Candidates
                        </Button>
                    </Box>

                    {organization &&
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>{user.email[0].toUpperCase()}</Avatar>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem disabled>
                        <Avatar /> User Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem disabled>
                        <Diversity3Icon /> &nbsp; Organization Settings
                    </MenuItem>
                    <Divider />
                    <Box display="flex" alignItems="center" mb={1} ml={1}>
                        <SwapHorizIcon />
                        <Typography variant="body1" style={{ marginLeft: 8 }}>Switch Organization</Typography>
                    </Box>
                    {
                        user.roles.map(role => {
                            let roleOrg = role.organization;
                            let disabled = roleOrg.uuid === organization.uuid;
                            return (
                                <MenuItem key={roleOrg.uuid} disabled={disabled} onClick={() => switchOrganization(roleOrg)}>
                                    {roleOrg.descriptiveName}
                                </MenuItem>
                            );
                        })
                    }
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
